/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Apr 25, 2018, 10:56:04 AM
    Author     : barrus
*/

body{
    background-color: #e7e4ee4d;
    font-family: 'Open Sans';
    font-size: 15px;
}

html, .form-control{
    font-family: 'Open Sans';
}


@for $i from 1 through 72 {
    $num: $i * 5;

    .d--rotate-#{$num} {
        transform: rotate(#{$num}deg);
        display: inline-block;
    }
}

@for $i from 1 through 20 {
    $num: $i * 5;

    .d--margin-top-neg-#{$num} {
        margin-top: -#{$num}px !important;
    }
}

@for $i from 1 through 100 {
    $num: $i * 10;

    .d--width-#{$num} {
        width: #{$num}px !important;
    }

    .d--tooltip-width-#{$num} {
        .tooltip-inner{
            width: #{$num}px !important;
            max-width: #{$num}px !important;
        }
    }
}

.m-tabs-line {
    border-bottom: 1px solid #ddd;
}

.m-header-menu .m-menu__nav > .m-menu__item .m-menu__submenu .m-menu__heading {
    display: block;
    -webkit-box-shadow: 1px 34px 52px -19px rgba(68, 62, 84, 0.03);
    -moz-box-shadow: 1px 34px 52px -19px rgba(68, 62, 84, 0.03);
    box-shadow: 1px 34px 52px -19px rgba(68, 62, 84, 0.03)
}

.m-header-menu .m-menu__nav > .m-menu__item .m-menu__submenu > .m-menu__subnav .m-menu__content .m-menu__inner {
    padding-top: 10px;
}

.property-card-row {
    margin-left: 0;
    margin-right: 0;

    .property-card{
        margin-top: 10px;
        cursor: pointer;

        .card{
            opacity: .8;
            &:hover{
                opacity: 1;
            }
            margin-left: 0;
            margin-right: 0;
        }

        .shadow{
            box-shadow: 0 1px 4px 0 rgba(0,0,0,0.15) !important;

            img, object{
                height: 150px;
                width: 100%;
            }

            &.placeholder{
                height:100%;
                width:100%;

                span{
                    width: 100%;
                    height: 100%;
                    background-color: #FFF;
                    color: $m--brand-color;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    font-weight: 600;
                    font-size: 20pt;
                    border-radius: .25rem;

                    &:hover{
                        background-color:$m--brand-color;
                        color: #FFF;
                    }
                }
            }
        }
    }


}

a:not(.btn):not(.m-menu__link):not(.m-nav__link):not(.m-tabs__link):not(.m-wizard__step-number):not(.link):not(.dropdown-item):not(.m-portlet__nav-link):not(.no-underline):not(.ui-state-default):not(.ui-corner-all):not(.m-datatable__pager-link), .dotted{
    color: #6c757d !important;
    text-decoration: none;
    overflow: hidden;
    position: relative;
    top: 5px;
    margin-top: -7px;
    display:inline-block;

    &:hover{
        text-decoration: none;
        /*border-bottom: 1px dotted;*/
        color: $m--brand-color !important;
    }

    &:after{
        content: ".................................................................................................";
        letter-spacing: 2pt;
        position: absolute;
        bottom: -7px;
        left: 0;
        font-size: 1.2rem;
    }
}

_:lang(x)::x-, a:not(.btn):not(.m-menu__link):not(.m-nav__link):not(.m-tabs__link):not(.m-wizard__step-number):not(.link):not(.dropdown-item):not(.m-portlet__nav-link):not(.no-underline):not(.ui-state-default):not(.ui-corner-all):not(.m-datatable__pager-link), .dotted{
    top: 0px !important;
}

.btn{
    font-family: 'Open Sans' !important;
    &.btn-secondary:not(.inherit){
        text-transform: uppercase;
        font-size: .8rem;
    }

    &.btn-brand, &.btn-outline-brand{
        &:hover{
            background-color: #6241c6;
            border-color: #6241c6;
        }
    }
}

.m-wizard{
    .btn-secondary{
        text-transform: inherit;
        font-size: inherit;
    }
}


h1, .h1 {
   font-size: 1.8rem !important;
}

h1, h2, h3, h4, h5, h6{
    font-family: 'Roboto' !important;
}

.m-separator.m-separator--dashed {
    border-bottom: 1px dashed #ddd;
}

.feature-blurb{
    min-height: 88px;
}

.smallify, .tag-row{
    font-size: 10pt;
}

.tinyify{
  font-size: 9pt;
}

.getting_started{
    text-align: center;
    .header{
        div.photocontainer{
            height:320px;
            img {
                width: 100%;
                height: 80%
            }
        }
    }
    .feature-blurb{
        min-height: 154px;

        &.smaller{
            min-height: 130px
        }
    }
}

.form-control[readonly] {
    background-color: #e9ecef !important;
}

.inner-icon{
    position: absolute;
    right: 3%;
    top: 57%;
}

.m-datatable__table{
    tr.noHover{
        &:hover{
            all: unset !important;
        }
    }
}

.portlet-col-link{
    a.link{
        color: inherit;

        &:hover{
            color: inherit;
            text-decoration: none;
        }
    }
    &:hover{
        background-color: #f4f5f8;
    }
}

.d--inline-block{
    display: inline-block;
}

.m-tooltip.tooltip .tooltip-inner{
    border-radius: 4px;
}

.col-xl-2-5{
    flex: 0 0 20%;
    max-width: 20%;
}

/*.tooltip-light{
    .tooltip{
        margin-top: -30px;
        .tooltip-inner{
            background-color: #fff;
            color: #000;
            -webkit-box-shadow: 0px 0px 5px -1px rgba(117,112,117,1);
            -moz-box-shadow: 0px 0px 5px -1px rgba(117,112,117,1);
            box-shadow: 0px 0px 5px -1px rgba(117,112,117,1);
        }
        .arrow{
            &:before{
                all: unset;
            }

            width: 50px;
            height: 50px;
            position: relative;
            overflow: hidden;

            &:after{
                content: "";
                position: absolute;
                width: 25px;
                height: 25px;
                background: #fff;
                transform: rotate(45deg);  Prefixes...
                bottom: -15px;
                left: 35%;
                box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5);
            }

                    &:after{
                        width: 0;
                        height: 0;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        border-top: 5px solid #fff;
                        content: " ";
                        position: absolute;
                        bottom: -10px;
                        left: 35%;
                        }
        }
        &.show{
            opacity: 1;
        }
    }
}*/

.dotted{
    /*text-decoration: dotted underline;*/
    cursor: pointer;
}

.pointer{
    cursor: pointer;
}

.checkerboard-container{
    padding: 0 14px !important;

    .table {
        margin-bottom: 0;
    }
}

table.checkerboard {
    tr {
        &:last-child td {
            border-bottom: 1px solid transparent;
        }
        &:not(:last-child) {
            border-bottom: 1px solid #f4f5f8;
        }
        td {
            width:25%;
            height: 150px;
            vertical-align: middle;
            /*border: 1px solid transparent;*/

            &.active, &:hover{
                cursor: pointer;
                background-color: #f4f5f8;
                /*border: 1px solid #f4f5f8;*/
            }
            &:not(:last-child) {
                border-right: 1px solid #f4f5f8;
            }

            &.empty{
                &.active, &:hover{
                    cursor: default;
                    background-color: #fff;
                }
            }
        }

    }

    &.align-top{
        tr{
            td{
                vertical-align: top;
            }
        }
    }

    &.no-height{
        tr{
            td{
                height:auto;
            }
        }
    }

    &.brand{
        tr{
            td{
                &.active{
                    background-color: $m--brand-color;
                    color: #fff;
                }
            }
        }
    }

    &.column4{
        tr{
            td{
                width:25%;
            }
        }
    }

    i {
        font-size: 3em;
    }
}

// body.era{
//   .property-card-row {
//     .property-card{
//         .shadow{
//             &.placeholder{
//                 span{
//                     color: #c9072a;
//                     &:hover{
//                         background-color: #c9072a;
//                         color: #fff;
//                     }
//                 }
//             }
//         }
//     }
//   }
//   table.checkerboard {
//     &.brand{
//         tr{
//             td{
//                 &.active{
//                     background-color: #c9072a;
//                     color: #fff;
//                 }
//             }
//         }
//     }
//   }
//   a:not(.btn):not(.m-menu__link):not(.m-nav__link):not(.m-tabs__link):not(.m-wizard__step-number):not(.link):not(.dropdown-item):not(.m-portlet__nav-link):not(.no-underline):not(.ui-state-default):not(.ui-corner-all):not(.m-datatable__pager-link), .dotted{
//     &:hover{
//         color: #c9072a !important;
//     }
//   }
//   .m-menu__stacked{
//     .m-menu__header{
//         color: #c9072a !important;
//     }
//   }
//   .m-subheader__breadcrumbs.m-nav {
//     .m-nav__item {
//       .m-nav__link {
//         &:hover {
//           .m-nav__link-text {
//             color: #c9072a;
//           }

//           .m-nav__link-icon {
//             color:#c9072a;
//           }
//         }
//       }
//     }
//   }

//   .btn-brand {
//     background-color: #c9072a;
//     border-color: #c9072a
//   }

//   .btn-brand.disabled,
//   .btn-brand:disabled {
//     background-color: #c9072a;
//     border-color: #c9072a
//   }

//   .btn-outline-brand {
//     color: #c9072a;
//     border-color: #c9072a
//   }

//   .btn-outline-brand:hover {
//     background-color: #c9072a;
//     border-color: #c9072a
//   }

//   .btn-outline-brand.disabled,
//   .btn-outline-brand:disabled {
//     color: #c9072a;
//   }

//   .btn-outline-brand:not(:disabled):not(.disabled).active,
//   .btn-outline-brand:not(:disabled):not(.disabled):active,
//   .show>.btn-outline-brand.dropdown-toggle {
//     background-color: #c9072a;
//     border-color: #c9072a
//   }

//   .m-nav.m-nav--skin-dark .m-nav__item.m-nav__item--active>.m-nav__link .m-nav__link-arrow,
//   .m-nav.m-nav--skin-dark .m-nav__item.m-nav__item--active>.m-nav__link .m-nav__link-icon,
//   .m-nav.m-nav--skin-dark .m-nav__item.m-nav__item--active>.m-nav__link .m-nav__link-text,
//   .m-nav.m-nav--skin-dark .m-nav__item:hover:not(.m-nav__item--disabled)>.m-nav__link .m-nav__link-arrow,
//   .m-nav.m-nav--skin-dark .m-nav__item:hover:not(.m-nav__item--disabled)>.m-nav__link .m-nav__link-icon,
//   .m-nav.m-nav--skin-dark .m-nav__item:hover:not(.m-nav__item--disabled)>.m-nav__link .m-nav__link-text {
//     color: #c9072a
//   }

//   .m-nav.m-nav--skin-dark .m-nav__item.m-nav__item--active>.m-nav__link .m-nav__link-bullet.m-nav__link-bullet--dot>span,
//   .m-nav.m-nav--skin-dark .m-nav__item.m-nav__item--active>.m-nav__link .m-nav__link-bullet.m-nav__link-bullet--line>span,
//   .m-nav.m-nav--skin-dark .m-nav__item:hover:not(.m-nav__item--disabled)>.m-nav__link .m-nav__link-bullet.m-nav__link-bullet--dot>span,
//   .m-nav.m-nav--skin-dark .m-nav__item:hover:not(.m-nav__item--disabled)>.m-nav__link .m-nav__link-bullet.m-nav__link-bullet--line>span {
//     background-color: #c9072a
//   }

//   .m-nav.m-nav--skin-dark .m-nav__section .m-nav__section-text {
//     color: #c9072a
//   }
// }

div.inventory, div.keyword, div.number, div.code, div.use {
    &.active, &:hover{
        cursor: pointer;
        background-color: #f4f5f8;
        border: 5px solid #eee;
    }
}

.m-datatable__table {
    tr {
        &:hover {
            cursor: pointer;
        }
        &.active {
            td, &:hover {
                background-color: #d6f5d6 !important;
            }
        }
        &.inactive{
            td, th{
                opacity:.5;
                background-color: #eee !important;
            }
            td, th, &:hover {
                cursor: default;
                color: #575962 !important;
            }
        }
        &.noclick{
            cursor: default;
        }
    }
}

.speech-bubble {
    position: relative;
    background: #ebedf2;
    border-radius: .4em;
    padding: 2px;

    textarea {
        background: #ebedf2;
        min-height: 100px;
    }

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 0;
        border: 0.469em solid transparent;
        border-top-color: #ebedf2;
        border-bottom: 0;
        border-left: 0;
        margin-left: -11.5em;
        margin-bottom: -0.469em;
    }

    &.speech-bubble-static{
        padding: 20px;
        background: #f6f7f9;

        &:after{
            border-top-color: #f6f7f9;
        }
    }
}

.number{
    min-height: 195px;
}

.keyword{
    min-height: 250px;
}

.m-separator--none{
    border-bottom: none;
}

.tag-row{
    span.tag{
        margin-right: 20px;
        span.category{
            color: #5867dd;
        }
        i.tag-delete, i.tag-clear{
            cursor: pointer;
            color: #5867dd;
            &:hover{
                color: #f4516c;
            }
        }
    }
}


#gallery {
    @media (min-width: 992px){
        .ui-sortable-handle{
            &.col-lg-2{
                margin-right: 1.16667%;
                max-width: 15.5%;
            }

            &.col-lg-3{
                margin-right: 2%;
                max-width: 23%;
            }
        }
    }
    .ui-sortable-handle{
        -webkit-box-shadow: 0px 0px 5px -1px rgba(117,112,117,1);
        -moz-box-shadow: 0px 0px 5px -1px rgba(117,112,117,1);
        box-shadow: 0px 0px 5px -1px rgba(117,112,117,1);
        padding: 10px;
        border-radius: 6px;

        i.delete{
            position: absolute;
            top: -15px;
            right: -10px;
            cursor: pointer;
            &:hover{
                color: #f4516c;
            }
        }
        img{
            width:100%;
            height: 150px;
        }
    }
}

#photoReplaceDiv{
    img{
        width:100%;
        max-height:200px;
    }
}

#photoDeleteDiv{
    img{
        width:100%;
    }
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.box
{
    font-size: 1.25rem; /* 20 */
    background-color: #c8dadf;
    position: relative;
    padding: 100px 20px;
}
.box.has-advanced-upload
{
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;

    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
}
.box.is-dragover
{
    outline-offset: -20px;
    outline-color: #c8dadf;
    background-color: #fff;
}
.box__dragndrop,
.box__icon
{
    display: none;
}
.box.has-advanced-upload .box__dragndrop
{
    display: inline;
}
.box.has-advanced-upload .box__icon
{
    width: 100%;
    height: 80px;
    fill: #92b0b3;
    display: block;
    margin-bottom: 40px;
}

.box.is-uploading .box__input,
.box.is-success .box__input,
.box.is-error .box__input
{
    visibility: hidden;
}

.box__uploading,
.box__success,
.box__error
{
    display: none;
}
.box.is-uploading .box__uploading,
.box.is-success .box__success,
.box.is-error .box__error
{
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;

    -webkit-transform: translateY( -50% );
    transform: translateY( -50% );
}
.box__uploading
{
    font-style: italic;
}
.box__success
{
    -webkit-animation: appear-from-inside .25s ease-in-out;
    animation: appear-from-inside .25s ease-in-out;
}
@-webkit-keyframes appear-from-inside
{
    from	{ -webkit-transform: translateY( -50% ) scale( 0 ); }
    75%		{ -webkit-transform: translateY( -50% ) scale( 1.1 ); }
    to		{ -webkit-transform: translateY( -50% ) scale( 1 ); }
}
@keyframes appear-from-inside
{
    from	{ transform: translateY( -50% ) scale( 0 ); }
    75%		{ transform: translateY( -50% ) scale( 1.1 ); }
    to		{ transform: translateY( -50% ) scale( 1 ); }
}

.box__restart
{
    font-weight: 700;
}
.box__restart:focus,
.box__restart:hover
{
    color: #39bfd3;
}

.js .box__file
{
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
.js .box__file + label
{
    max-width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
}
.js .box__file + label:hover strong,
.box__file:focus + label strong,
.box__file.has-focus + label strong
{
    color: #39bfd3;
}
.js .box__file:focus + label,
.js .box__file.has-focus + label
{
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}
.js .box__file + label *
{
    /* pointer-events: none; */ /* in case of FastClick lib use */
}

.no-js .box__file + label
{
    display: none;
}

.no-js .box__button
{
    display: block;
}
.box__button
{
    font-weight: 700;
    color: #e5edf1;
    background-color: #39bfd3;
    display: none;
    padding: 8px 16px;
    margin: 40px auto 0;
}
.box__button:hover,
.box__button:focus
{
    background-color: #0f3c4b;
}

.noUi-tooltip {
    display: none;
}
.noUi-active .noUi-tooltip {
    display: block;
}

@media (max-width: 1024px){
    .m-nav__item{
        &.mobile{
            position: absolute;
            z-index: 1000;
            left: 0;
            top: -10px;
            padding: 0;
            margin: 0;
        }
    }

    .mobile-hide{
        display:none !important;
    }
}

#message-history{
    .message{
        display: inline-block;
        position: relative;
        width: 55%;
        padding-right: 50px;
        clear: both;
        margin: 10px;

        .msg-body {
            background-color: #5767d9;
            border: none;
            border-radius: 5px;
            padding: 10px 14px;
            position: relative;
            color: #fff;

            .messageText{
                word-wrap: break-word;
            }
        }

        &.mt{
            float: right;
            text-align: right;

            .msg-body {

            }
        }

        &.mo{
            float: left;
            padding-right: 0;
            padding-left: 50px;

            .msg-body {
                background-color: #edeff2;
                color: #000;
                border: none;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
            }
        }

    }

}

#conversation_list{
    .m-widget3__item{
        padding: 10px 0 0 10px;
        cursor: pointer;
    }
}

.m-widget3__item{
    &.active{
        background-color: #f4f5f8;
    }
}

/*.m-topbar__userpic{
    width: 70px;
    height: 70px;

    img{
        vertical-align: top;
        width: 100%;
        height: auto;
    }
}*/

.avatar{
    border-radius: 50%;
    overflow: hidden;
    width: 3rem;
    height: 3rem;

    &.avatar-mt{
        margin-top: 20%;
    }

    &.avatar-medium{
        width: 3rem;
        height: 3rem;
    }

    &.avatar-large{
        width: 5rem;
        height: 5rem;
    }

    img {
        width: 100%;
        height: auto;
    }
}

.m-topbar .m-topbar__nav.m-nav > .m-nav__item > .m-nav__link .m-topbar__userpic img{
    width: 3rem;
    height: 3rem;
}

.m-card-user .m-card-user__pic img{
    width: 70px;
    height: 70px;
}

.m-form{
    .m-form__actions{
        &.m-form__actions--gradient{
            border-top: 1px solid #e7e4ee8d;
            background: url(/assets/media/img/sectionbg3.png) no-repeat top center;
        }
    }
}

.m-portlet__head-text {
    display: table-cell;
    vertical-align: middle;
    font-size: 1.3rem;
    font-weight: 500 !important;
    font-family: 'Roboto' !important;
}

.m-menu__link-text{
    font-weight: 500 !important;
    font-family: 'Roboto' !important;
}

.m-portlet{
    border-radius: 4px;
    .m-portlet__nav-item{
        .m-portlet__nav-link{
            .m-topbar__userpic{
                img {
                    display: block;
                    vertical-align: middle;
                    max-width: 3rem !important;
                    margin: 0 0 0 5px;
                }
            }
        }
    }
    &.getting_started{
        .m-portlet__body{
            padding: 2.2rem 4rem;
        }
    }
    .m-portlet__body{
        a.float{
            position: absolute;
            top: calc(50% - 10px);
            left: 2rem;
            font-weight: 600;
        }
    }
}

@media (min-width: 992px){
    .dashboard_feature_portlets{
        .m-portlet__body{
            height: 710px;
        }
        .m-portlet__foot{
            height: 150px;
        }
    }
}

.getting_started_section{
    min-height: 885px;
}

.banner{
    img{
        float: right;
        margin-top: -2.2rem;
        width: 80%;
    }
}

.add-on-row td{
    padding-top:0 !important;
    padding-bottom:0 !important;
    font-weight: 300 !important;
}

.chat-item {

    .bubble {
        padding: 8px 12px;
        background-color: aliceblue;
        -webkit-box-shadow: 0 1px 4px -2px rgba(0,0,0,0.3);
        -moz-box-shadow: 0 1px 4px -2px rgba(0,0,0,0.3);
        -o-box-shadow: 0 1px 4px -2px rgba(0,0,0,0.3);
        box-shadow: 0 1px 4px -2px rgba(0,0,0,0.3);
        border-radius: 14px 10px 10px 0;
        position: relative;
        max-width: 600px;
        width: 100%;
        font-size: 1rem;
        font-weight: 400;
        color: #575962;


        .message {
            /*word-break: break-all;*/
            padding: 10px;
        }

        .time {
            margin-top: 4px;
            font-size: 11px;
            color: #adb5bd;
        }

        textarea{
            background-color: #FFF;
            padding: 10px;
            height: 100px;
        }
        textarea[readonly]{
            background-color: aliceblue !important;
            border: none;
        }
    }
}
.flex-nowrap {
    flex-wrap: nowrap !important;
}
.d-flex {
    display: flex !important;
}

.m-invoice-2 .m-invoice__wrapper .m-invoice__body table tbody tr td, .m-invoice-2 .m-invoice__wrapper .m-invoice__footer .m-invoice__table table tbody tr td{
    font-weight: 400;
}

.m-topbar .m-topbar__nav.m-nav>.m-nav__item.m-topbar__notifications.m-topbar__notifications--img.m-dropdown--arrow .m-dropdown__arrow {
    color: #7e55dd;
}

.logo-image img {
    height: 20px;
}

.m-timeline-2 .m-timeline-2__items .m-timeline-2__item .m-timeline-2__item-cricle > i.center {
    top: -0.63rem;
    left: -0.65rem;
}

.m-timeline-2:before {
    left: 7.7rem;
}

.m-timeline-2 .m-timeline-2__items .m-timeline-2__item .m-timeline-2__item-cricle {
    left: 6.9rem;
}

.m-timeline-2 .m-timeline-2__items .m-timeline-2__item .m-timeline-2__item-text {
    padding-left: 8rem;
}

.ds_rule_detail_markets {
    font-size: 1.1em;

    .bootstrap-tagsinput {
        width: 100%;
    }

    .m-badge{
        border-radius: .25em;
    }
}


.fade-to-black{
  background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));
  background-blend-mode: multiply;

  img{
    opacity: .8;
  }
}

@media (min-width: 1025px){
  .kt-portlet.kt-portlet--height-fluid {
      height: calc(100% - 20px);
  }
}
.kt-portlet {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
    box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
    background-color: #ffffff;
    margin-bottom: 20px;
    border-radius: 4px;
}

.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.kt-portlet .kt-portlet__head.kt-portlet__head--noborder {
  border-bottom: 0;
}
.kt-portlet .kt-portlet__head {
  -webkit-transition: left 0.3s, right 0.3s, height 0.3s;
  transition: left 0.3s, right 0.3s, height 0.3s;
}
.kt-portlet .kt-portlet__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  padding: 0 25px;
  border-bottom: 1px solid #ebedf2;
  min-height: 60px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.kt-portlet .kt-portlet__head .kt-portlet__head-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: flex-first;
  align-content: flex-first;
}

.kt-portlet .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title {
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  font-weight: 500;
  color: #464457;
}

.kt-portlet .kt-portlet__head .kt-portlet__head-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: end;
  align-content: flex-end;
}

@media (min-width: 1025px){
.kt-portlet.kt-portlet--height-fluid .kt-portlet__body {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}
}
.kt-portlet .kt-portlet__body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 25px;
    border-radius: 4px;
}

.kt-widget.kt-widget--user-profile-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.kt-widget.kt-widget--user-profile-2 .kt-widget__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: -45px;
}

.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__img {
  max-width: 90px;
  border-radius: 50%;
}

.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--success {
  background: rgba(10, 187, 135, 0.1);
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__pic {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 90px;
  height: 90px;
  font-size: 1.5rem;
  border-radius: 50%;
}

.kt-font-success {
  color: #0abb87 !important;
}
.kt-font-boldest {
  font-weight: 700 !important;
}
.kt-hidden, .kt-hide {
  display: none !important;
}

.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__info {
  padding-left: 1rem;
}

.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__info .kt-widget__username {
  font-size: 1.4rem;
  color: #464457;
  font-weight: 500;
}

.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__info .kt-widget__desc {
  display: block;
  font-weight: 500;
  font-size: 1.1rem;
  padding-top: 0.4rem;
  color: #a7abc3;
}

.kt-widget.kt-widget--user-profile-2 .kt-widget__body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__section {
  padding: 1rem 0 1rem 0;
  color: #6c7293;
  font-weight: 400;
}

.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__section a {
  padding-right: 0.3rem;
}

.kt-link {
  text-decoration: none;
  position: relative;
  display: inline-block;
  color: #505ae2;
}
.kt-font-brand {
  color: #5d78ff !important;
}
.kt-font-bold {
  font-weight: 500 !important;
}
.kt-font-transform-u {
  text-transform: uppercase;
}

.kt-widget.kt-widget--user-profile-2 .kt-widget__item {
  padding: 0.7rem 0;
}

.kt-widget.kt-widget--user-profile-2 .kt-widget__item .kt-widget__contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}

.kt-widget.kt-widget--user-profile-2 .kt-widget__item .kt-widget__contact .kt-widget__label {
  color: #464457;
  font-weight: 600;
}

.kt-widget.kt-widget--user-profile-2 .kt-widget__item .kt-widget__contact .kt-widget__data {
  color: #a7abc3;
  font-weight: 400;
}

.kt-widget.kt-widget--user-profile-2 .kt-widget__footer {
  margin-top: 2rem;
}

.kt-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0;
  padding: 0; }
  .kt-pagination .kt-pagination__links, .kt-pagination .kt-pagination__links li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .kt-pagination .kt-pagination__links {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    .kt-pagination .kt-pagination__links li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-right: 0.5rem;
      border-radius: 4px; }
      .kt-pagination .kt-pagination__links li a {
        font-weight: 600;
        color: #a7abc3;
        font-size: 1.1rem;
        padding: 0 0.2rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        min-width: 30px;
        min-height: 30px;
        margin: 0; }
    .kt-pagination .kt-pagination__links .kt-pagination__link--active a {
      color: #ffffff; }
  .kt-pagination .kt-pagination__toolbar {
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0; }
    .kt-pagination .kt-pagination__toolbar .form-control {
      padding: 0;
      margin-right: 10px;
      height: 30px;
      font-weight: 600;
      font-size: 1.1rem;
      font-weight: 500;
      line-height: 1;
      outline: none;
      border: none; }
    .kt-pagination .kt-pagination__toolbar .pagination__desc {
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      font-weight: 500;
      color: #a7abc3; }
  .kt-pagination.kt-pagination--brand .kt-pagination__links .kt-pagination__link--first, .kt-pagination.kt-pagination--brand .kt-pagination__links .kt-pagination__link--next, .kt-pagination.kt-pagination--brand .kt-pagination__links .kt-pagination__link--prev, .kt-pagination.kt-pagination--brand .kt-pagination__links .kt-pagination__link--last {
    background: rgba(93, 120, 255, 0.1); }
  .kt-pagination.kt-pagination--brand .kt-pagination__links .kt-pagination__link--active {
    background: #5d78ff; }
  .kt-pagination.kt-pagination--brand .kt-pagination__links li:hover {
    background: #5d78ff;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease; }
    .kt-pagination.kt-pagination--brand .kt-pagination__links li:hover a {
      color: #ffffff; }
      .kt-pagination.kt-pagination--brand .kt-pagination__links li:hover a i {
        color: #fff !important; }
  .kt-pagination.kt-pagination--brand .kt-pagination__toolbar .form-control {
    background: rgba(93, 120, 255, 0.1); }
  .kt-pagination.kt-pagination--light .kt-pagination__links .kt-pagination__link--first, .kt-pagination.kt-pagination--light .kt-pagination__links .kt-pagination__link--next, .kt-pagination.kt-pagination--light .kt-pagination__links .kt-pagination__link--prev, .kt-pagination.kt-pagination--light .kt-pagination__links .kt-pagination__link--last {
    background: rgba(255, 255, 255, 0.1); }
  .kt-pagination.kt-pagination--light .kt-pagination__links .kt-pagination__link--active {
    background: #ffffff; }
  .kt-pagination.kt-pagination--light .kt-pagination__links li:hover {
    background: #ffffff;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease; }
    .kt-pagination.kt-pagination--light .kt-pagination__links li:hover a {
      color: #ffffff; }
      .kt-pagination.kt-pagination--light .kt-pagination__links li:hover a i {
        color: #fff !important; }
  .kt-pagination.kt-pagination--light .kt-pagination__toolbar .form-control {
    background: rgba(255, 255, 255, 0.1); }
  .kt-pagination.kt-pagination--dark .kt-pagination__links .kt-pagination__link--first, .kt-pagination.kt-pagination--dark .kt-pagination__links .kt-pagination__link--next, .kt-pagination.kt-pagination--dark .kt-pagination__links .kt-pagination__link--prev, .kt-pagination.kt-pagination--dark .kt-pagination__links .kt-pagination__link--last {
    background: rgba(40, 42, 60, 0.1); }
  .kt-pagination.kt-pagination--dark .kt-pagination__links .kt-pagination__link--active {
    background: #282a3c; }
  .kt-pagination.kt-pagination--dark .kt-pagination__links li:hover {
    background: #282a3c;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease; }
    .kt-pagination.kt-pagination--dark .kt-pagination__links li:hover a {
      color: #ffffff; }
      .kt-pagination.kt-pagination--dark .kt-pagination__links li:hover a i {
        color: #fff !important; }
  .kt-pagination.kt-pagination--dark .kt-pagination__toolbar .form-control {
    background: rgba(40, 42, 60, 0.1); }
  .kt-pagination.kt-pagination--primary .kt-pagination__links .kt-pagination__link--first, .kt-pagination.kt-pagination--primary .kt-pagination__links .kt-pagination__link--next, .kt-pagination.kt-pagination--primary .kt-pagination__links .kt-pagination__link--prev, .kt-pagination.kt-pagination--primary .kt-pagination__links .kt-pagination__link--last {
    background: rgba(88, 103, 221, 0.1); }
  .kt-pagination.kt-pagination--primary .kt-pagination__links .kt-pagination__link--active {
    background: #5867dd; }
  .kt-pagination.kt-pagination--primary .kt-pagination__links li:hover {
    background: #5867dd;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease; }
    .kt-pagination.kt-pagination--primary .kt-pagination__links li:hover a {
      color: #ffffff; }
      .kt-pagination.kt-pagination--primary .kt-pagination__links li:hover a i {
        color: #fff !important; }
  .kt-pagination.kt-pagination--primary .kt-pagination__toolbar .form-control {
    background: rgba(88, 103, 221, 0.1); }
  .kt-pagination.kt-pagination--success .kt-pagination__links .kt-pagination__link--first, .kt-pagination.kt-pagination--success .kt-pagination__links .kt-pagination__link--next, .kt-pagination.kt-pagination--success .kt-pagination__links .kt-pagination__link--prev, .kt-pagination.kt-pagination--success .kt-pagination__links .kt-pagination__link--last {
    background: rgba(10, 187, 135, 0.1); }
  .kt-pagination.kt-pagination--success .kt-pagination__links .kt-pagination__link--active {
    background: #0abb87; }
  .kt-pagination.kt-pagination--success .kt-pagination__links li:hover {
    background: #0abb87;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease; }
    .kt-pagination.kt-pagination--success .kt-pagination__links li:hover a {
      color: #ffffff; }
      .kt-pagination.kt-pagination--success .kt-pagination__links li:hover a i {
        color: #fff !important; }
  .kt-pagination.kt-pagination--success .kt-pagination__toolbar .form-control {
    background: rgba(10, 187, 135, 0.1); }
  .kt-pagination.kt-pagination--info .kt-pagination__links .kt-pagination__link--first, .kt-pagination.kt-pagination--info .kt-pagination__links .kt-pagination__link--next, .kt-pagination.kt-pagination--info .kt-pagination__links .kt-pagination__link--prev, .kt-pagination.kt-pagination--info .kt-pagination__links .kt-pagination__link--last {
    background: rgba(85, 120, 235, 0.1); }
  .kt-pagination.kt-pagination--info .kt-pagination__links .kt-pagination__link--active {
    background: #5578eb; }
  .kt-pagination.kt-pagination--info .kt-pagination__links li:hover {
    background: #5578eb;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease; }
    .kt-pagination.kt-pagination--info .kt-pagination__links li:hover a {
      color: #ffffff; }
      .kt-pagination.kt-pagination--info .kt-pagination__links li:hover a i {
        color: #fff !important; }
  .kt-pagination.kt-pagination--info .kt-pagination__toolbar .form-control {
    background: rgba(85, 120, 235, 0.1); }
  .kt-pagination.kt-pagination--warning .kt-pagination__links .kt-pagination__link--first, .kt-pagination.kt-pagination--warning .kt-pagination__links .kt-pagination__link--next, .kt-pagination.kt-pagination--warning .kt-pagination__links .kt-pagination__link--prev, .kt-pagination.kt-pagination--warning .kt-pagination__links .kt-pagination__link--last {
    background: rgba(255, 184, 34, 0.1); }
  .kt-pagination.kt-pagination--warning .kt-pagination__links .kt-pagination__link--active {
    background: #ffb822; }
  .kt-pagination.kt-pagination--warning .kt-pagination__links li:hover {
    background: #ffb822;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease; }
    .kt-pagination.kt-pagination--warning .kt-pagination__links li:hover a {
      color: #ffffff; }
      .kt-pagination.kt-pagination--warning .kt-pagination__links li:hover a i {
        color: #fff !important; }
  .kt-pagination.kt-pagination--warning .kt-pagination__toolbar .form-control {
    background: rgba(255, 184, 34, 0.1); }
  .kt-pagination.kt-pagination--danger .kt-pagination__links .kt-pagination__link--first, .kt-pagination.kt-pagination--danger .kt-pagination__links .kt-pagination__link--next, .kt-pagination.kt-pagination--danger .kt-pagination__links .kt-pagination__link--prev, .kt-pagination.kt-pagination--danger .kt-pagination__links .kt-pagination__link--last {
    background: rgba(253, 57, 122, 0.1); }
  .kt-pagination.kt-pagination--danger .kt-pagination__links .kt-pagination__link--active {
    background: #fd397a; }
  .kt-pagination.kt-pagination--danger .kt-pagination__links li:hover {
    background: #fd397a;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease; }
    .kt-pagination.kt-pagination--danger .kt-pagination__links li:hover a {
      color: #ffffff; }
      .kt-pagination.kt-pagination--danger .kt-pagination__links li:hover a i {
        color: #fff !important; }
  .kt-pagination.kt-pagination--danger .kt-pagination__toolbar .form-control {
    background: rgba(253, 57, 122, 0.1); }
  .kt-pagination.kt-pagination--circle .kt-pagination__links li {
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%; }
  .kt-pagination.kt-pagination--lg .kt-pagination__links li a {
    font-size: 1.3rem;
    min-width: 35px;
    min-height: 35px; }
  .kt-pagination.kt-pagination--lg .kt-pagination__toolbar .form-control {
    height: 35px;
    font-size: 1.2rem;
    padding: 0.2rem 0 0.2rem 0.2rem; }
  .kt-pagination.kt-pagination--sm .kt-pagination__links li a {
    font-size: 1rem;
    min-width: 25px;
    min-height: 25px; }
  .kt-pagination.kt-pagination--sm .kt-pagination__toolbar .form-control {
    height: 25px;
    font-size: 0.9rem; }

@media (max-width: 768px) {
  .kt-pagination .kt-pagination__links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .kt-pagination .kt-pagination__links li {
      margin: 0.3rem 0.5rem 0.3rem 0; }
      .kt-pagination .kt-pagination__links li a {
        font-size: 0.9rem;
        min-width: 25px;
        min-height: 25px; }
  .kt-pagination .kt-pagination__toolbar .form-control {
    height: 25px;
    font-size: 0.9rem;
    padding: 0.2rem 0 0.2rem 0.2rem; } }

    .pagination {
      display: flex;
      padding-left: 0;
      list-style: none;
      border-radius: 2px; }

    .page-link {
      position: relative;
      display: block;
      padding: 0.5rem 0.75rem;
      margin-left: -1px;
      line-height: 1.25;
      color: #3f51b5;
      background-color: #fff;
      border: 1px solid #dee2e6; }
      .page-link:focus, .page-link:hover {
        color: #0056b3;
        text-decoration: none;
        background-color: #e9ecef;
        border-color: #dee2e6; }
      .page-link:not([disabled]):not(.disabled) {
        cursor: pointer; }

    .page-item:first-child .page-link {
      margin-left: 0;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px; }
    .page-item:last-child .page-link {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px; }
    .page-item.active .page-link {
      z-index: 1;
      color: #fff;
      background-color: #3f51b5;
      border-color: #3f51b5; }
    .page-item.disabled .page-link {
      color: #868e96;
      pointer-events: none;
      cursor: auto;
      background-color: #fff;
      border-color: #dee2e6; }

    .pagination-lg .page-link {
      padding: 0.75rem 1.5rem;
      font-size: 18px;
      line-height: 1.5; }
    .pagination-lg .page-item:first-child .page-link {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px; }
    .pagination-lg .page-item:last-child .page-link {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px; }

    .pagination-sm .page-link {
      padding: 0.25rem 0.5rem;
      font-size: 14px;
      line-height: 1.5; }
    .pagination-sm .page-item:first-child .page-link {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px; }
    .pagination-sm .page-item:last-child .page-link {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px; }
