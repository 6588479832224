/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Jul 19, 2018, 10:52:26 AM
    Author     : barrus
*/

.fs-xs{
    font-size: .75em !important;
}

.fs-sm{
    font-size: .875em !important;
}

.fs-md{
    font-size: 1em !important;
}

.fs-lg{
    font-size: 1.33333em !important;
}

.fs-2x{
    font-size: 2em !important;
}

.fs-3x{
    font-size: 3em !important;
}

.fs-4x{
    font-size: 4em !important;
}

.fs-5x{
    font-size: 5em !important;
}

.fs-7x{
    font-size: 7em !important;
}

.fs-10x{
    font-size: 10em !important;
}