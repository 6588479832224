//
//** Right Aside
//

//== Base markup
.m-aside-right {
	width: array-get($m-config-aside, aside-right, self, width);
	padding: array-get($m-config-aside, aside-right, self, padding, desktop);
	background: array-get($m-config-aside, aside-right, self, bg-color);
	@include shadow(array-get($m-config-aside, aside-right, self, shadow, desktop));
}

// Desktop mode
@include desktop {
	.m-header--fixed .m-aside-right {
		//padding-top: array-get($m-config-header, header, layout, self, default, height, desktop);
	}
}

// Tablet and Mobile mode
@include tablet-and-mobile {
	.m-aside-right {
		width: auto;	
		padding: array-get($m-config-aside, aside-right, self, padding, mobile);
		margin: array-get($m-config-aside, aside-right, self, margin, mobile);
		@include shadow(array-get($m-config-aside, aside-right, self, shadow, mobile));
	}
}