/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Mar 20, 2018, 3:15:19 PM
    Author     : barrus
*/

.m-menu__link.btn {
    margin: 9px 30px;
    width: auto !important;
}

.m-menu__separator{
    margin-right: 10px;
    border-color: #f6f7fa;
}

.m-menu__stacked{
    .m-menu__desc-text{
        font-size: 1.2em !important;
        padding: 25px 0 !important;
    }
    .bold{
        font-weight: 500 !important;
        font-size: 1.07rem !important;
    }
    .m-menu__header{
        color:$m--brand-color !important;
        font-size: 1em !important;
    }
}

.m-menu__item{
    hr.drop-shadow{
        height: 12px;
        border: 0;
        margin: 10px 20px 5px;
        box-shadow: inset 0 12px 12px -12px rgba(100, 100, 100, 0.2);
    }
    .btn{
        margin-bottom:20px;
        padding:0.65rem 1rem !important;
    }
    .nav-btn{
        margin: 0 10px 0 -10px;
        font-size: 8pt;
        padding: .45rem 1rem !important;

        i{
            font-size: 1rem;
        }
    }
}

.standoutLeft{
    /*background: -webkit-linear-gradient(right, rgba(200,200,200,0), rgba(200,200,200,.3));*/
    background: url(/assets/media/img/left.png);
    height: 50px;
    width: 30px;
    display: block;
    margin-top: 15px;
}

.standoutRight{
    /*background: -webkit-linear-gradient(left, rgba(200,200,200,0), rgba(200,200,200,.3));*/
    background: url(/assets/media/img/right.png);
    height: 50px;
    width: 30px;
    display: block;
    margin-top: 15px;
}
